import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CtrlChainIcons } from '@cca-common/core';

@Component({
  standalone: true,
  imports: [MatIconModule],
  selector: 'cca-icon',
  template: `
    <mat-icon
      [inline]="inline"
      fontSet="cca-icon"
      [fontIcon]="'cca-icon-' + icon"
    ></mat-icon>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IconComponent {
  @Input({ required: true }) icon!: CtrlChainIcons;
  @Input() inline = true;
}
